import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 673.000000 661.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,661.000000) scale(0.100000,-0.100000)">

<path d="M3010 6543 c-19 -1 -84 -8 -145 -13 -275 -26 -644 -127 -925 -252
-77 -34 -237 -116 -279 -143 -22 -14 -43 -25 -46 -25 -4 0 -21 -11 -39 -25
-18 -14 -37 -25 -43 -25 -10 0 -164 -103 -217 -145 -17 -14 -35 -29 -41 -33
-142 -107 -363 -319 -475 -457 -35 -44 -68 -84 -73 -90 -98 -120 -253 -367
-331 -530 -32 -66 -61 -122 -65 -125 -10 -7 -64 -138 -101 -245 -17 -49 -35
-101 -40 -115 -35 -96 -99 -387 -122 -550 -17 -127 -18 -648 0 -790 13 -104
19 -135 66 -340 51 -219 169 -567 203 -595 6 -5 24 -37 39 -70 78 -174 266
-464 424 -656 47 -56 310 -322 371 -374 116 -100 310 -237 409 -289 30 -16 62
-35 70 -42 16 -14 290 -152 355 -179 477 -199 991 -282 1500 -241 205 16 226
19 405 55 189 37 205 41 225 50 11 5 79 28 150 51 163 53 193 64 335 130 132
62 263 135 403 226 54 35 100 64 103 64 9 0 122 88 238 185 102 85 300 287
374 380 35 44 72 89 81 100 9 11 32 43 51 70 18 28 36 52 39 55 10 8 71 100
71 106 0 3 11 20 23 37 52 70 247 455 247 487 0 8 62 174 84 225 28 62 95 320
110 420 22 143 46 348 46 389 0 46 3 52 49 96 73 70 85 131 45 225 -20 49 -96
95 -156 95 -117 0 -198 -76 -198 -186 0 -62 37 -125 86 -149 l37 -17 -7 -136
c-4 -75 -11 -139 -15 -142 -5 -3 -14 -44 -21 -92 -22 -155 -103 -487 -135
-547 -8 -17 -15 -35 -15 -42 0 -45 -156 -380 -255 -549 -168 -284 -449 -607
-717 -823 -25 -20 -49 -37 -53 -37 -3 0 -26 -15 -49 -32 -24 -18 -52 -39 -62
-45 -11 -7 -44 -30 -74 -51 -111 -76 -308 -183 -470 -254 -268 -117 -641 -214
-923 -239 -172 -15 -647 -6 -786 15 -249 39 -532 118 -746 210 -97 42 -322
157 -395 201 -47 29 -98 59 -115 68 -16 8 -70 45 -120 82 -318 235 -605 541
-797 850 -114 184 -238 426 -238 465 0 9 -11 34 -25 54 -51 75 -140 384 -180
621 -39 235 -48 357 -42 613 4 191 10 268 31 397 44 266 125 558 190 680 24
45 80 165 142 303 35 77 135 241 222 362 69 97 108 146 201 255 45 52 323 325
332 325 3 0 22 14 40 31 19 16 45 38 59 47 14 9 60 43 102 75 43 32 86 61 95
65 10 4 72 39 138 79 66 39 165 92 220 118 55 26 118 56 140 67 62 31 388 138
489 160 287 63 464 82 771 82 251 -1 406 -16 622 -60 221 -45 451 -118 632
-201 40 -18 76 -32 78 -29 9 9 -155 79 -322 139 -228 82 -488 139 -735 162
-137 13 -452 18 -550 8z"/>
<path d="M4630 6225 c0 -5 96 -55 107 -55 4 0 -2 7 -15 15 -26 19 -92 47 -92
40z"/>
<path d="M4755 6162 c17 -17 108 -65 100 -53 -3 6 -30 23 -58 37 -29 15 -47
22 -42 16z"/>
<path d="M4890 6080 c14 -10 27 -17 29 -14 6 5 -31 33 -44 33 -5 1 1 -8 15
-19z"/>
<path d="M4978 6024 c30 -20 55 -34 57 -32 5 5 -90 68 -102 68 -4 0 16 -16 45
-36z"/>
<path d="M5074 5961 c7 -11 46 -35 46 -28 0 3 -12 12 -26 22 -15 9 -23 12 -20
6z"/>
<path d="M5230 5856 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M3175 5639 c-4 -19 -16 -51 -26 -72 -27 -52 -137 -166 -227 -235 -42
-31 -83 -63 -92 -70 -13 -12 -13 -13 5 -7 38 14 94 22 89 14 -3 -5 -14 -9 -24
-9 -45 0 -50 -9 -50 -91 0 -46 4 -79 10 -79 6 0 10 -9 10 -20 0 -13 -7 -20
-20 -20 -13 0 -18 5 -14 15 4 8 1 15 -5 15 -8 0 -10 -12 -5 -37 6 -33 5 -36
-12 -29 -10 4 -15 4 -11 0 4 -4 1 -14 -6 -23 -11 -15 13 2 92 61 20 15 43 31
51 35 8 4 54 47 101 95 64 66 97 108 125 166 38 75 39 80 39 182 0 114 -16
173 -30 109z"/>
<path d="M3271 5434 c-1 -31 -40 -121 -79 -183 -45 -70 -212 -237 -284 -283
-7 -4 -8 -8 -3 -8 6 0 16 5 23 12 6 6 15 8 19 3 5 -4 2 -10 -4 -12 -17 -6 -16
-20 1 -26 7 -3 13 0 13 6 -1 40 4 67 13 67 6 0 10 -33 10 -80 0 -47 -4 -80
-10 -80 -5 0 -10 -18 -10 -40 0 -22 -5 -40 -11 -40 -5 0 -8 -4 -4 -10 8 -12
122 55 163 97 18 18 38 33 43 33 6 0 8 4 5 9 -3 4 3 16 14 26 10 9 41 51 69
94 66 102 89 194 74 295 -11 75 -41 159 -42 120z m-251 -404 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M2770 5220 c-14 -11 -20 -20 -15 -19 13 0 50 28 44 33 -2 3 -15 -4
-29 -14z"/>
<path d="M3886 5003 c15 -21 45 -56 66 -77 21 -22 38 -46 38 -54 0 -20 72
-102 83 -95 6 3 7 1 3 -5 -4 -7 8 -27 29 -47 20 -21 35 -45 34 -58 -1 -17 -3
-14 -10 11 -5 17 -14 32 -19 32 -6 0 -10 -1 -10 -3 0 -9 -80 -381 -86 -399 -4
-14 -2 -18 5 -14 16 10 14 -4 -3 -23 -8 -9 -17 -36 -21 -61 -3 -25 -15 -81
-27 -125 -11 -44 -21 -90 -22 -102 -1 -12 -7 -28 -13 -35 -6 -7 -14 -33 -18
-58 -3 -25 -15 -79 -25 -120 -10 -41 -21 -92 -24 -114 -4 -21 -9 -42 -12 -47
-2 -5 -13 -52 -24 -106 -10 -54 -26 -129 -35 -168 -9 -38 -17 -77 -19 -85 -1
-8 -5 -22 -8 -30 -4 -8 -11 -44 -18 -80 -14 -85 -38 -193 -55 -255 -8 -27 -17
-72 -20 -98 -2 -27 -7 -53 -10 -58 -4 -5 109 -9 284 -9 l291 0 6 28 c3 15 9
47 14 72 5 25 18 83 29 130 38 163 52 243 47 263 -2 10 -2 16 2 13 3 -4 16 35
28 86 17 68 20 95 11 100 -8 5 -6 9 5 13 10 4 19 24 23 53 4 26 20 106 36 177
16 72 41 182 55 245 14 63 30 135 35 160 6 25 11 54 13 65 1 11 5 27 8 35 3 8
18 76 33 150 15 74 31 151 37 170 5 19 22 96 38 170 16 74 37 176 48 225 11
50 23 107 26 128 l6 37 -441 0 -441 0 28 -37z m67 -10 c-7 -2 -19 -2 -25 0 -7
3 -2 5 12 5 14 0 19 -2 13 -5z m714 -135 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m5 -90 c-8 -8 -9 -4 -5 13 4 13 8 18 11 10 2 -7 -1 -18 -6
-23z m-15 -80 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-37 -165
c0 -5 -5 -15 -10 -23 -8 -12 -10 -11 -10 8 0 12 5 22 10 22 6 0 10 -3 10 -7z
m-549 -78 c-6 -35 -9 -41 -10 -20 -1 30 9 78 15 73 2 -2 -1 -26 -5 -53z m2
-80 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m-15 -65 c0 -9 -3
-7 -8 5 -5 11 -8 29 -8 40 0 16 2 15 8 -5 4 -14 8 -32 8 -40z m509 28 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-80 -170 c-3 -7 -5 -2 -5 12 0
14 2 19 5 13 2 -7 2 -19 0 -25z m36 -3 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15
9 16 15 13 8 -4z m-16 -47 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-510 -32 c-1 -19 -6 -31 -10 -27 -7 7 0 61 8 61 2 0 3 -15 2 -34z m515
-44 c-6 -15 -14 -42 -17 -60 -4 -18 -9 -31 -11 -29 -3 3 3 38 13 78 10 41 19
66 21 56 2 -10 -1 -31 -6 -45z m-85 -254 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2
-7 2 -19 0 -25z m-526 -59 c-5 -13 -10 -19 -10 -12 -1 15 10 45 15 40 3 -2 0
-15 -5 -28z m506 -11 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m27 -12 c-3 -15 -8 -25 -11 -23 -2 3 -1 17 3 31 3 15 8 25 11 23 2 -3 1 -17
-3 -31z m-17 -68 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-520
-50 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-63 -172 c-3 -15
-8 -25 -11 -23 -2 3 -1 17 3 31 3 15 8 25 11 23 2 -3 1 -17 -3 -31z m525 -70
c-6 -26 -14 -44 -17 -42 -2 3 1 26 8 51 17 59 24 52 9 -9z m-32 -18 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-507 8 c0 -3 -4 -8 -10 -11 -5 -3
-10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-23 -88 c-3 -7 -5 -2 -5 12 0
14 2 19 5 13 2 -7 2 -19 0 -25z m30 -30 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2
-7 2 -19 0 -25z m-70 -140 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m533 18 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z m-12 -96 c0 -9 -3 -7 -8 5 -5 11 -8 29 -8 40 0 16 2 15 8 -5 4 -14
8 -32 8 -40z m-537 17 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z
m516 -39 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-516 -49 c-5
-13 -10 -19 -10 -12 -1 15 10 45 15 40 3 -2 0 -15 -5 -28z m-24 -41 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M3178 4883 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1681 4605 c-75 -190 -9 -365 212 -562 20 -18 40 -33 45 -33 4 0 17
-8 28 -17 29 -27 132 -93 204 -131 36 -19 67 -37 70 -41 8 -11 120 -49 120
-41 0 13 -27 31 -41 27 -8 -2 -11 -2 -6 0 4 3 7 11 7 19 0 9 5 12 10 9 6 -3
10 3 10 14 0 10 -6 22 -12 25 -10 5 -10 7 0 12 8 4 12 31 12 85 0 56 4 79 13
79 14 1 -100 60 -114 60 -6 0 -15 5 -22 10 -7 6 -59 38 -117 72 -135 79 -147
87 -244 183 -89 87 -132 160 -145 241 l-7 49 -23 -60z m654 -554 c-2 -4 -19
-5 -37 -3 -31 5 -32 6 -8 12 27 7 52 2 45 -9z m-118 -177 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M1572 4329 c-12 -30 -25 -73 -28 -97 -4 -23 -11 -45 -15 -48 -12 -7
-12 -54 0 -54 4 0 11 -17 14 -39 10 -74 65 -171 141 -246 70 -69 212 -177 278
-211 18 -9 35 -20 38 -24 3 -4 46 -29 95 -55 50 -27 99 -54 110 -62 11 -7 24
-13 30 -13 5 0 3 5 -5 10 -10 6 -11 10 -2 10 6 0 12 5 12 11 0 5 -4 8 -9 5
-12 -8 -41 4 -41 16 0 6 11 7 25 4 14 -4 25 -2 25 4 0 5 -8 10 -17 10 -14 0
-15 2 -5 9 11 6 9 9 -7 14 -18 4 -20 14 -23 83 -3 67 -6 79 -20 78 -10 -1 -14
4 -11 12 6 17 8 17 36 0 33 -21 67 -29 67 -17 0 6 -5 11 -11 11 -6 0 -54 25
-107 57 -321 188 -459 321 -521 504 l-28 81 -21 -53z m585 -555 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m15 -225 c-12 -5 -30 -5 -39 -2 -15 6
-15 7 3 13 10 4 27 5 38 2 18 -5 18 -6 -2 -13z"/>
<path d="M2400 4026 c0 -2 15 -9 33 -15 29 -10 30 -9 13 4 -18 14 -46 20 -46
11z"/>
<path d="M2400 4001 c0 -9 30 -24 36 -18 2 1 -6 8 -16 15 -11 7 -20 8 -20 3z"/>
<path d="M2370 3801 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2275 3710 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M2250 3649 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2516 3275 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"/>
<path d="M3161 2594 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3556 2468 l24 -38 -24 -107 c-17 -78 -21 -110 -13 -115 8 -5 6 -9
-6 -14 -10 -3 -17 -17 -17 -33 -1 -14 -9 -65 -19 -113 -10 -49 -18 -89 -17
-89 0 -1 26 -3 58 -4 l56 -1 22 105 c17 81 20 112 12 133 -9 24 -6 33 17 65
17 24 32 62 39 103 16 93 22 122 28 132 3 4 -21 8 -53 9 -32 1 -74 2 -95 3
l-36 3 24 -39z m112 15 c-21 -2 -55 -2 -75 0 -21 2 -4 4 37 4 41 0 58 -2 38
-4z m-10 -20 c-16 -2 -40 -2 -55 0 -16 2 -3 4 27 4 30 0 43 -2 28 -4z m-94
-207 c-3 -15 -8 -25 -11 -23 -2 3 -1 17 3 31 3 15 8 25 11 23 2 -3 1 -17 -3
-31z m46 24 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m7 -132 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-10
-95 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z"/>
<path d="M3888 2502 c-27 -2 -48 -7 -48 -11 0 -7 -50 -90 -128 -209 -38 -60
-39 -45 5 -247 l28 -131 58 26 58 25 -6 60 c-3 33 -11 85 -17 115 -16 89 -17
114 -1 138 8 13 11 33 8 45 -5 16 -4 18 3 8 8 -11 15 -5 35 25 13 21 36 53 51
69 14 17 26 34 26 38 0 5 7 15 15 23 30 31 23 33 -87 26z m55 -19 c-13 -2 -35
-2 -50 0 -16 2 -5 4 22 4 28 0 40 -2 28 -4z m4 -19 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-105 -36 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19
2 -3 -1 -11 -7 -17z m58 -23 c-7 -9 -15 -13 -19 -10 -3 3 1 10 9 15 21 14 24
12 10 -5z m-30 -55 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m-127 -65 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8
-4z m44 -67 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-17 2 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m77 -252
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-55 -24 c-13 -9 -26
-13 -30 -9 -8 8 17 25 38 25 9 0 6 -6 -8 -16z"/>
<path d="M4090 2498 c0 -5 4 -8 9 -8 5 0 14 -13 21 -30 7 -16 17 -30 22 -30 6
0 6 -6 -1 -14 -13 -15 -57 -211 -98 -433 l-6 -33 60 0 60 0 12 63 c7 34 18 89
26 122 7 33 18 80 24 104 5 24 7 50 4 57 -3 8 -1 14 5 14 5 0 15 33 21 73 6
39 14 82 17 95 5 19 2 22 -22 24 -77 5 -154 3 -154 -4z m158 -20 c-3 -8 -24
-13 -56 -14 l-52 -1 52 6 53 5 -65 6 -65 7 68 1 c51 2 68 -1 65 -10z m-88
-110 c0 -36 -4 -35 -14 5 -4 16 -2 27 4 27 5 0 10 -14 10 -32z m-23 -40 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-10 -40 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m72 -48 c0 -9 -4 -8 -9 5 -5 11 -9 27 -9
35 0 9 4 8 9 -5 5 -11 9 -27 9 -35z m-52 -2 c-3 -7 -5 -2 -5 12 0 14 2 19 5
13 2 -7 2 -19 0 -25z m-30 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m73 -28 c4 -3 1 -21 -6 -40 l-13 -35 6 39 c4 26 2 37 -5 32 -6 -4 -8 2
-4 16 4 16 7 18 10 8 3 -8 8 -17 12 -20z m-123 -232 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M4360 2502 c0 -2 10 -16 21 -32 25 -33 23 -72 -10 -215 -55 -233 -63
-275 -58 -290 5 -11 21 -15 57 -15 46 0 51 3 61 27 5 15 13 47 16 72 3 25 10
51 15 57 4 6 6 18 2 27 -3 8 -1 18 5 22 6 3 11 18 11 33 0 34 17 64 23 42 29
-105 61 -189 70 -183 6 4 7 1 3 -6 -5 -7 -3 -25 3 -42 6 -16 16 -42 22 -58
l11 -30 30 27 c34 30 44 51 53 113 4 24 10 50 15 59 4 8 11 38 14 65 4 28 11
66 16 85 5 19 16 71 25 115 8 44 20 92 24 108 9 25 7 27 -17 27 -15 0 -43 -3
-63 -6 l-36 -6 -22 -87 c-11 -48 -21 -95 -21 -104 0 -34 -28 -17 -35 21 -3 20
-11 44 -16 52 -8 11 -9 9 -3 -10 3 -14 3 -22 -1 -18 -4 4 -9 29 -10 55 -2 26
-9 59 -17 73 -12 24 -17 25 -101 25 -48 0 -87 -1 -87 -3z m118 -19 c-15 -2
-42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z m235 0 c-7 -2 -19 -2 -25 0
-7 3 -2 5 12 5 14 0 19 -2 13 -5z m-250 -20 c-13 -2 -33 -2 -45 0 -13 2 -3 4
22 4 25 0 35 -2 23 -4z m234 -35 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m40 -15 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m13
-53 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-240 -80 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m-129 -81 c-5 -13 -10 -19 -10 -12 -1 15 10 45 15 40 3 -2 0 -15 -5 -28z
m159 1 c0 -11 -2 -20 -4 -20 -2 0 -6 9 -9 20 -3 11 -1 20 4 20 5 0 9 -9 9 -20z
m-120 -35 c0 -2 -7 -5 -15 -9 -9 -3 -15 0 -15 9 0 9 6 12 15 9 8 -4 15 -7 15
-9z m127 -27 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-140 -30
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m20 0 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m264 -1 c-10 -9 -11 -8 -5 6 3 10 9 15 12
12 3 -3 0 -11 -7 -18z m-314 -49 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m60 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-24
-13 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m264 -17 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-34 -69 c-5 -5 -6 11 -3 34 4
41 5 41 8 8 2 -18 0 -37 -5 -42z m-226 19 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13
2 -7 2 -19 0 -25z m250 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-47 -8 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"/>
<path d="M5108 2502 l-167 -2 -11 -43 c-6 -23 -15 -57 -20 -74 -8 -31 -6 -37
13 -34 5 1 6 -3 2 -9 -3 -6 -10 -8 -15 -5 -4 3 -10 -5 -13 -16 -6 -21 19 -68
36 -69 15 0 6 19 -15 34 -16 11 -18 16 -8 17 26 2 84 6 98 8 6 0 12 12 12 25
0 14 5 28 10 31 6 3 10 15 10 26 0 21 -1 21 68 22 42 1 47 -1 50 -22 3 -16 8
-21 20 -16 9 3 13 3 9 -1 -4 -4 12 -18 34 -31 23 -13 44 -22 46 -20 2 2 10 39
18 82 12 64 13 81 3 89 -8 7 -73 10 -180 8z m95 -19 c-46 -2 -120 -2 -165 0
-46 1 -9 3 82 3 91 0 128 -2 83 -3z m0 -20 c-46 -2 -120 -2 -165 0 -46 1 -9 3
82 3 91 0 128 -2 83 -3z m-253 -38 c0 -14 -4 -25 -9 -25 -4 0 -6 11 -3 25 2
14 6 25 8 25 2 0 4 -11 4 -25z m127 9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z"/>
<path d="M1470 2488 c0 -3 10 -19 23 -35 l24 -28 -29 -125 c-15 -69 -29 -138
-30 -155 -1 -16 -5 -34 -9 -40 -4 -5 -12 -39 -19 -75 -6 -36 -13 -73 -16 -82
-5 -17 8 -18 171 -18 138 0 176 3 173 13 -3 6 1 26 8 42 10 24 13 26 14 9 0
-11 -6 -26 -12 -33 -11 -11 -10 -13 2 -9 17 5 38 67 39 115 1 17 6 35 12 38 6
4 8 13 5 21 -3 8 -2 22 3 32 9 17 50 217 59 284 3 26 0 39 -9 43 -22 7 -409
11 -409 3z m303 -15 c-51 -2 -135 -2 -185 0 -51 1 -10 3 92 3 102 0 143 -2 93
-3z m-83 -23 c12 -8 12 -10 -5 -10 -11 0 -27 5 -35 10 -12 8 -12 10 5 10 11 0
27 -5 35 -10z m185 0 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z
m-118 -36 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-5 -20 c3
-3 2 -22 -3 -42 -11 -44 -25 -113 -39 -184 -15 -82 -32 -138 -42 -138 -5 0 -6
-4 -3 -10 3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 -17 10 -39 10 -40 0 -67
20 -58 42 3 8 6 4 7 -7 0 -13 6 -6 15 19 8 22 12 43 9 47 -3 5 0 16 6 26 8 15
30 122 44 218 2 11 4 21 5 23 2 5 108 2 113 -4z m106 -45 c-3 -8 -7 -3 -11 10
-4 17 -3 21 5 13 5 -5 8 -16 6 -23z m-351 -51 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m0 -50 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m0 -60 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m265 -20
c-6 -6 -7 0 -4 19 5 21 7 23 10 9 2 -10 0 -22 -6 -28z m-192 12 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-53 -32 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m197 -22 c-3 -15 -8 -25 -11 -23
-2 3 -1 17 3 31 3 15 8 25 11 23 2 -3 1 -17 -3 -31z m-154 -9 c0 -7 -4 -6 -10
3 -8 12 -10 12 -10 1 0 -8 -3 -12 -6 -8 -9 9 6 28 17 22 5 -4 9 -12 9 -18z
m227 -59 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M2430 2489 c0 -4 15 -25 33 -47 25 -32 39 -41 65 -41 17 -1 32 -5 32
-10 0 -17 -59 -306 -66 -324 -4 -11 -1 -17 7 -17 10 -1 9 -4 -3 -14 -11 -8
-18 -30 -20 -57 l-3 -44 56 -3 56 -3 26 108 c14 59 31 135 37 168 30 176 34
190 57 193 12 2 24 11 26 20 2 9 7 -4 11 -29 l8 -46 49 3 c27 1 55 9 63 16 11
12 29 91 27 121 -1 9 -461 15 -461 6z m348 -16 c-60 -2 -156 -2 -215 0 -60 1
-11 3 107 3 118 0 167 -2 108 -3z m-58 -17 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2
-9 4 6 10 25 14 25 6z m80 0 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25
14 25 6z m73 -3 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-354
-17 c8 -10 9 -16 1 -21 -5 -3 -10 0 -10 9 0 8 -6 17 -12 19 -8 4 -9 6 -2 6 6
1 17 -5 23 -13z m68 -338 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-60 -10 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m73 -48 c0 -18
-20 -20 -21 -3 -1 10 -5 5 -9 -12 -7 -29 -8 -29 -9 -4 0 14 2 28 5 32 8 7 34
-3 34 -13z m-20 -77 c-11 -17 -11 -17 -6 0 3 10 6 24 7 30 0 9 2 9 5 0 3 -7 0
-20 -6 -30z m-70 3 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z"/>
<path d="M2131 2453 c-8 -21 -22 -56 -33 -78 -10 -22 -31 -69 -45 -105 -14
-36 -34 -84 -44 -107 -11 -23 -19 -47 -19 -53 0 -6 -16 -44 -35 -84 -19 -41
-35 -79 -35 -85 0 -12 36 -14 93 -5 30 5 41 13 54 41 14 30 14 36 2 45 -11 8
-11 9 3 5 9 -4 19 1 22 10 7 16 -1 29 -42 75 l-24 26 56 7 c31 4 57 11 57 16
2 15 5 23 16 49 6 14 24 55 39 92 16 37 30 65 33 62 3 -3 6 -59 6 -124 0 -133
2 -130 -72 -130 -45 0 -46 0 -28 -20 10 -11 22 -17 28 -13 6 4 8 0 4 -9 -6
-17 54 -83 66 -72 3 4 2 -7 -3 -25 -8 -28 -6 -32 16 -37 34 -9 114 5 115 19 0
7 1 126 2 265 2 246 2 252 -18 253 -17 1 -16 2 5 9 16 5 -17 8 -90 9 l-116 1
-13 -37z m115 20 c-60 -3 -83 -8 -93 -21 -13 -15 -13 -14 -3 6 10 20 17 22 93
20 l82 -2 -79 -3z m103 -40 c-1 -22 -2 -23 -6 -8 -3 11 -10 23 -17 27 -7 5 -5
8 7 8 12 0 17 -7 16 -27z m-223 -100 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2
-7 1 -15 -2 -18z m45 -50 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13
-6 -23z m176 -55 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z
m-287 -18 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m40 -15 c-7 -8 -20 -15 -29 -15 -11 0 -8 5 9 14 33 19 35 19 20 1z m24
-20 c-4 -8 -16 -15 -28 -15 -20 0 -20 1 4 14 14 8 26 14 27 15 1 1 0 -6 -3
-14z m-100 -51 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m169
-31 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-163 -23 c0 -5 8
-10 18 -11 14 0 14 -2 -3 -9 -27 -12 -43 -12 -25 0 10 6 11 10 3 10 -7 0 -13
5 -13 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m14 -75 c-4 -8 -8 -15 -10 -15 -2
0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m-4 -35 c0 -5 -10 -10 -22 -9
-22 0 -22 1 -3 9 11 5 21 9 23 9 1 1 2 -3 2 -9z m305 0 c3 -5 -1 -10 -10 -10
-9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z"/>
<path d="M3113 2473 c-3 -10 -26 -65 -50 -123 -65 -155 -80 -195 -81 -214 0
-5 -4 -12 -9 -15 -4 -3 -24 -46 -44 -96 l-37 -90 57 -3 c48 -3 59 0 76 20 11
13 27 43 34 65 14 41 13 43 -18 78 -17 20 -29 40 -27 44 3 5 28 6 56 3 46 -4
50 -3 50 17 0 18 -6 21 -39 21 -22 0 -42 -5 -44 -10 -3 -6 -4 -5 -3 2 2 8 21
14 47 16 42 3 46 6 58 39 7 19 24 61 38 91 19 44 27 53 34 40 5 -8 7 -35 4
-59 -2 -24 -7 -77 -11 -116 l-7 -73 -56 0 c-31 0 -51 -4 -46 -8 6 -4 31 -30
58 -59 35 -39 47 -60 47 -84 l0 -32 62 7 c34 3 63 8 65 9 11 12 19 499 8 517
-3 6 -52 11 -113 12 l-107 2 110 6 110 6 -108 2 c-95 2 -108 0 -114 -15z m160
-20 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m54 -75 c-3 -7 -5
-2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-90 -30 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m-154 -18 c-3 -11 -7 -20 -9 -20 -2 0 -4 9 -4 20
0 11 4 20 9 20 5 0 7 -9 4 -20z m189 -56 c-4 -23 -6 -24 -15 -9 -8 14 2 32 19
35 1 0 -1 -12 -4 -26z m-252 -104 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z m73 -7 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24
-2 18 -5z m60 -70 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m14
-35 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-117 -13 c0 -8 -2
-15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m260 -85 c0
-5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12 20 0z"/>
<path d="M4857 2123 c-27 -135 -28 -148 -12 -159 17 -12 323 -12 343 0 6 3 13
30 17 59 3 28 10 66 15 82 16 57 21 105 11 105 -5 0 -11 -6 -14 -12 -2 -7 -3
-5 -2 4 2 10 -8 29 -22 43 -23 23 -33 25 -109 25 -46 0 -84 -4 -84 -9 0 -5 16
-23 35 -40 19 -17 35 -33 35 -37 0 -4 7 -12 16 -19 15 -10 15 -18 4 -63 l-12
-52 -52 0 c-29 0 -55 3 -58 6 -3 3 0 29 8 57 16 61 18 107 4 107 -5 0 -7 -8
-4 -17 5 -14 4 -15 -4 -4 -6 8 -8 20 -5 27 6 16 -17 30 -26 15 -5 -8 -13 -5
-26 9 -10 11 -21 20 -24 20 -3 0 -19 -66 -34 -147z m308 117 c3 -5 2 -10 -4
-10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m-239 -27 c-6 -6
-36 9 -36 18 0 5 9 4 20 -3 10 -7 18 -14 16 -15z m180 -23 c8 0 14 -4 14 -10
0 -5 -4 -10 -8 -10 -12 0 -32 22 -31 35 0 5 3 4 6 -2 2 -7 11 -13 19 -13z m1
-132 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-244 -13 c-3 -9
-8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
